import { symbolName } from "typescript";
import * as chains from "viem/chains";

// export const targetChain = chains.base;

export const usdtReceiverAddress = "0x3d96A6e3DE9878cdE588D30D7AD216fB13d0Be4e"; // 替换为已部署的合约地址
export const usdtTokenAddress = "0x2e17c757DDf00646eD3dA1d4FA66271DC13976eC"; // 替换为在 Sepolia 上的实际 USDT 合约地址

export const tokenPool = "/#/pool";
export const howItWork = "/#/howitwork";
export const faq = "/#/faq";
export const telegram = "https://t.me/+k6bK3vUzpmZlODMx";
export const x = "https://x.com/plus1s_io?t=iUbE_glebfAxnEho2_lyNA&s=05";
export const linktree = "https://linktr.ee/plus1s";

export const viewLink = "https://en.wikipedia.org/wiki/Kim_Jong_Un";

export const mobileTip = `The mobile site is under construction. Please open our website with a computer browser to experience the full functionality. We will complete the mobile site as soon as possible.`;

const development = {
  targetChain: chains.baseSepolia,
  usdtReceiverAddress: "0x3d96A6e3DE9878cdE588D30D7AD216fB13d0Be4e", // 替换为已部署的合约地址
  usdtTokenAddress: "0x2e17c757DDf00646eD3dA1d4FA66271DC13976eC", // 替换为在 Sepolia 上的实际 USDT 合约地址
  rpc: 'https://base-sepolia.g.alchemy.com/v2/YNXmJQn1Aw88FYAzPU61PAtAPbk0FqaW',
  symbolName: "Celebrity",
  usdtDecimals: 18,
  celebrityDecimals: 18,
  basescan: "https://sepolia.basescan.org/address/0x3d96A6e3DE9878cdE588D30D7AD216fB13d0Be4e#tokentxns",
  baseURL: "https://staging.plus1s.io",
  domain: "https://staging.plus1s.io",
  uniswap: "https://app.uniswap.org/swap?outputCurrency=0x126564fA2C5400Fd4e2343fb09fC10681351E19E&inputCurrency=0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913"
};

const test = {
  targetChain: chains.baseSepolia,
  usdtReceiverAddress: "0x3d96A6e3DE9878cdE588D30D7AD216fB13d0Be4e", // 替换为已部署的合约地址
  usdtTokenAddress: "0x2e17c757DDf00646eD3dA1d4FA66271DC13976eC", // 替换为在 Sepolia 上的实际 USDT 合约地址
  rpc: 'https://base-sepolia.g.alchemy.com/v2/YNXmJQn1Aw88FYAzPU61PAtAPbk0FqaW',
  symbolName: "Celebrity",
  usdtDecimals: 18,
  celebrityDecimals: 18,
  basescan: "https://sepolia.basescan.org/address/0x3d96A6e3DE9878cdE588D30D7AD216fB13d0Be4e#tokentxns",
  baseURL: "https://staging.plus1s.io",
  domain: "https://staging.plus1s.io",
  uniswap: "https://app.uniswap.org/swap?outputCurrency=0x126564fA2C5400Fd4e2343fb09fC10681351E19E&inputCurrency=0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913"
};

const production = {
  targetChain: chains.base,
  usdtReceiverAddress: "0x126564fA2C5400Fd4e2343fb09fC10681351E19E", // 替换为已部署的合约地址
  usdtTokenAddress: "0x833589fcd6edb6e08f4c7c32d4f71b54bda02913", // 替换为在 Sepolia 上的实际 USDT 合约地址
  rpc: 'https://base-mainnet.g.alchemy.com/v2/cgFOdwOYyuMdZO3QGAs9fBkUl9vzSTY8',
  symbolName: "KJUD",
  usdtDecimals: 6,
  celebrityDecimals: 18,
  basescan: "https://basescan.org/address/0x126564fA2C5400Fd4e2343fb09fC10681351E19E#tokentxns",
  baseURL: "https://plus1s.io",
  domain: "https://plus1s.io",
  uniswap: "https://app.uniswap.org/swap?outputCurrency=0x126564fA2C5400Fd4e2343fb09fC10681351E19E&inputCurrency=0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913"
};

const _config =
  process.env.REACT_APP_ENV == "production"
    ? production
    : process.env.REACT_APP_ENV == "test"
      ? test
      : development;

export default { ..._config };