import React, { useEffect, useState } from 'react'
import { useAccount, useChains ,useChainId, useDisconnect, useSwitchChain, useWatchAsset, useReadContract} from 'wagmi'
import { withMobx } from '../MobxHoc';
import WalletIcon from '../../../assets/images/Icon-Wallet.svg'
import config from '../../../config';
import {formatAdress, getWalletCurrentChain, fromatNumToThousands, convertToDisplayValue} from '../../../utils'
import Dialog from '../Dialog/index'
import NetworkIcon from '../../../assets/images/Icon-Network.svg'
import WrongIcon from '../../../assets/images/Icon-Wrong.svg';
import LoadIcon from '../../../assets/images/Icon-Loading.svg';
import { useNavigate, useLocation } from 'react-router-dom'
import receiverABI from '../../../abi/celebrity.json';
import './index.css'
import ClaimDialog from '../../pool/claimDialog';
import AccountStatement from '../../pool/AccountStatement';

let _path
function Index(props) {
  const { closeHandle , store} = props
  const account = useAccount()
  const currentChains = useChains()
  const chainId = useChainId()
  const { disconnect } = useDisconnect()
  const { switchChain } = useSwitchChain()
  const title = 'Wallet connected';
  const targetChain = config.targetChain;
  const location = useLocation();
  const [noConnect, setNoConnect] = useState(!(account && account.address))
  const [wrongChain, setWrongChain] = useState(account.chainId !== targetChain.id);
  const [currentChainName, setCurrentChainName] = useState("unknown");
  const [show,setShow] = useState(true)
  const [adding, setAdding] = useState(false);
  const [contractBalance, setContractBalance] = useState(0);
  const [claimAmount, setClaimAmount] = useState(0);
  const [showClaimDialog, setShowClaimDialog] = useState(false)
  const [currentToken, setCurrentToken] = useState('KJUD')
  const [showStatement, setShowStatement] = useState(false)

  const usdtReceiverAddress = config.usdtReceiverAddress;
  const { watchAsset } = useWatchAsset()
  const { data: usdcBalance } = useReadContract({ abi: receiverABI, address: usdtReceiverAddress, functionName: 'balanceOfUSDT', args: [account.address] });

  const addWallet = async () => {
    if(!currentToken){return;}
    setAdding(true)
    await watchAsset({
      type: 'ERC20',
      options: {
        address: config.usdtReceiverAddress,
        symbol: config.symbolName,
        decimals: 18,
        
      },
    }, {onSuccess: ()=>{setAdding(false)},
     onError: (e)=>{
      console.error(e)
      setAdding(false)
     }})
  }

  const claimHandle = ()=>{
    setShowClaimDialog(true)
  }

  const tokenChangeHandle = (token) => {
    if(token == currentToken){
      setCurrentToken('')
      return;
    }
    setCurrentToken(token)
  }

  useEffect(()=>{
    setWrongChain(account.chainId !== targetChain.id)
    // setCurrentChainName(getWalletCurrentChain(chainId) || "unknown")
    const curChain = getWalletCurrentChain(account.chainId)
    if(curChain){
      setCurrentChainName(curChain.name || "unknown")
    }
    if(!account || !account.chainId){
      setNoConnect(true)
    }else{
      setNoConnect(false)
    }
  },[account.chainId])

  useEffect(()=>{
    const curChain = getWalletCurrentChain(account.chainId)
    if(curChain){
      setCurrentChainName(curChain.name || "unknown")
    }
  }, [])

  const disconnectHandle = () => {
    store.user.setAuthToken('')
    window.localStorage.removeItem('token');
    disconnect();
    closeHandle();
  }

  useEffect(()=>{
    if(_path !== location.pathname){
      if(_path){
        closeHandle()
      }
      _path = location.pathname
      
    }
    
  }, [location.pathname])

  useEffect(() => {
    if (usdcBalance || usdcBalance === 0n) {
      setClaimAmount(usdcBalance);
      setContractBalance(fromatNumToThousands(convertToDisplayValue(usdcBalance, config.usdtDecimals, 4), false));
    }
  }, [usdcBalance]);

  return (
    <div>
      <Dialog title = {''} closeHandle={closeHandle} className='userConnectedDialog'>
        <div className='connectedDialogContent'>
          <div className='connectedWrap itemWrap'>
            <h3>{title}</h3>
            <div className={'netWork '+(wrongChain ? 'wrong': '')}>
              {wrongChain && !noConnect && <p className='wrongTip'><i className='icon'><img src={WrongIcon} width={'12px'} height={'12px'} /></i><span>Woring Network</span></p> }
              <p className='network'><i className='icon'><img src={NetworkIcon} width={'12px'} height={'12px'} /></i><span>{ currentChainName }</span></p>
            </div>
            <div className='address'>
              <img src={WalletIcon} width={'24px'} height={'24px'} />
              <p>{formatAdress(account.address)}</p>
            </div>
            { wrongChain && !noConnect ? <div className='switchWrap'>
              <p>Switch from Ethereum Network to Blast Network to continue.</p>
              <button className='switchBtn'  onClick={() => switchChain({ chainId: targetChain.id })}>Switch Network</button>
            </div> : null }
            <div className='disconnectWrap'  onClick={() => {disconnectHandle()}}>
              <button className='button button_border disconnectBtn'>Disconnect</button>
            </div>
          </div>
          <div className='addWalletWrap itemWrap'>
            <div className='tokensWrap'>
              <span className={'token '+(currentToken == 'KJUD' ? 'selected '  : '')} onClick={()=>tokenChangeHandle('KJUD')}>KJUD</span>
            </div>
            <span className={'button button_border addBtn '+(currentToken ? '' : 'disabled')} onClick={addWallet}>Add Contracts to Wallet</span>
          </div>
          <div className='claimWrap itemWrap'>
            <div className='top'>
              <div className='available'>
                <p className='label'>Available Earnings</p>
                <p className='number'>{contractBalance}<span>USDC</span></p>
              </div>
              <span className={'button button_border claimBtn '+(account.address && usdcBalance > 0n ? '' : ' disabled ')} onClick={account.address && usdcBalance > 0n ? claimHandle : claimHandle}>CLAIM</span>
            </div>
            <p className='link' onClick={()=>{setShowStatement(true)}}>
              <span>Account Statement &gt;</span>
            </p>
          </div>
        </div>
      </Dialog>
      { adding && <span className={'addLoading '+(adding ? 'open' : '')}><img className='loadicon' src={LoadIcon} width={'24px'} height={'24px'} /></span>}
      <ClaimDialog openClaimDialog = {showClaimDialog}  closeHandle = {()=>{setShowClaimDialog(false)}} claimAmount = {claimAmount}></ClaimDialog>
      {showStatement ? <AccountStatement closeHandle={()=>{setShowStatement(false)}}></AccountStatement> : null}
    </div>
  )
}

export default withMobx(Index)
